import { template as template_af5b26b7443440c8b0d7f90466e98b2a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_af5b26b7443440c8b0d7f90466e98b2a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
