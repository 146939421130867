import { template as template_a17e576a5ecb4371af092076b28f63b2 } from "@ember/template-compiler";
const FKLabel = template_a17e576a5ecb4371af092076b28f63b2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
